import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Client } from '@twilio/conversations';
import { Conversation } from '@twilio/conversations/';
import { Message } from '@twilio/conversations/';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { CHAT_ENGINES } from '../../constants';
import axios from 'axios';
import { useAppState } from '../../state';

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  hasUnreadMessages: boolean;
  messages: Message[];
  conversation: Conversation | null;
};

export const ChatContext = createContext<ChatContextType>(null!);

export const getChatMessages = async (uuid: string, chatId: string) => {
  const response = await axios.get(`https://api.chatengine.io/chats/${chatId}/messages/`, {
    headers: {
      'Project-ID': CHAT_ENGINES.PROJECT_ID,
      'User-Name': uuid,
      'User-Secret': uuid,
    },
  });
  return response.data;
};
export const sendMessage = async (uuid: string, chatId: string, text: string, attachment_urls?: string[]) => {
  const response = await axios.post(
    `https://api.chatengine.io/chats/${chatId}/messages/`,
    {
      text: text,
      attachment_urls: attachment_urls,
    },
    {
      headers: {
        'Project-ID': CHAT_ENGINES.PROJECT_ID,
        'User-Name': uuid,
        'User-Secret': uuid,
      },
    }
  );
  return response.data;
};

export const useChatSubscription = (
  messages: Message[],
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
) => {
  const { roomInfo } = useAppState();

  React.useEffect(() => {
    if (!roomInfo.uuid) return;
    const socket = new WebSocket(
      `wss://api.chatengine.io/person/?publicKey=${CHAT_ENGINES.PROJECT_ID}&username=${roomInfo.uuid}&secret=${roomInfo.uuid}`
    );
    socket.onmessage = event => {
      if (event.data) {
        const data = JSON.parse(event.data);
        console.log(data);
        if (data.action === 'new_message') {
          const newMessages = {
            author: data.data.message.sender.first_name,
            body: data.data.message.text,
            sid: data.data.message.id,
            index: data.data.message.id,
            type: data.data.message.attachments.length > 0 ? 'media' : 'text',
          } as Message;

          if (messages) {
            const _messages = [...messages, newMessages];
            _messages.sort((a: any, b: any) => {
              return a.created - b.created;
            });

            setMessages(_messages);
          } else {
            setMessages([newMessages]);
          }
        }
      }
    };
    return () => {
      socket.close();
    };
  }, [roomInfo.uuid, messages, setMessages]);
};

export const ChatProvider: React.FC = ({ children }) => {
  const { room, onError } = useVideoContext();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [chatClient, setChatClient] = useState<Client>();
  const { roomInfo } = useAppState();
  useChatSubscription(messages, setMessages);

  const connect = useCallback(
    (token: string) => {
      const client = new Client(token);

      const handleClientInitialized = (state: string) => {
        if (state === 'initialized') {
          // @ts-ignore
          window.chatClient = client;
          setChatClient(client);
        } else if (state === 'failed') {
          onError(new Error("There was a problem connecting to Twilio's conversation service."));
        }
      };

      client.on('stateChanged', handleClientInitialized);

      return () => {
        client.off('stateChanged', handleClientInitialized);
      };
    },
    [onError]
  );
  useEffect(() => {
    if (roomInfo.uuid && roomInfo.chatId) {
      const _uuid = roomInfo.uuid;
      const _chatId = roomInfo.chatId;
      getChatMessages(_uuid, _chatId)
        .then(messages => {
          const newMessages = messages.map((message: any) => {
            return {
              author: message.sender.first_name,
              body: message.text,
              sid: message.id,
              index: message.id,
              type: message.attachments.length > 0 ? 'media' : 'text',
            } as Message;
          });
          setMessages(newMessages);
        })
        .catch(e => {
          console.error(e);
        });
    }
  }, [roomInfo.uuid, roomInfo.chatId]);

  // useEffect(() => {
  //   if (conversation) {
  //     const handleMessageAdded = (message: Message) => setMessages(oldMessages => [...oldMessages, message]);
  //     conversation.getMessages().then(newMessages => setMessages(newMessages.items));
  //     conversation.on('messageAdded', handleMessageAdded);
  //     return () => {
  //       conversation.off('messageAdded', handleMessageAdded);
  //     };
  //   }
  // }, [conversation]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  // useEffect(() => {
  //   if (room && chatClient) {
  //     chatClient
  //       .getConversationByUniqueName(room.sid)
  //       .then(newConversation => {
  //         //@ts-ignore
  //         window.chatConversation = newConversation;
  //         setConversation(newConversation);
  //       })
  //       .catch(e => {
  //         console.error(e);
  //         onError(new Error('There was a problem getting the Conversation associated with this room.'));
  //       });
  //   }
  // }, [room, chatClient, onError]);

  return (
    <ChatContext.Provider
      value={{ isChatWindowOpen, setIsChatWindowOpen, connect, hasUnreadMessages, messages, conversation }}
    >
      {children}
    </ChatContext.Provider>
  );
};
