import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name?: string;
  roomName?: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { roomInfo } = useAppState();
  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Join a Room
      </Typography>
      <Typography variant="body1">Press Continue to join the room with the following details:</Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-user-name">
              Your Name
            </InputLabel>
            <TextField
              id="input-user-name"
              variant="outlined"
              fullWidth
              size="small"
              value={roomInfo.userName}
              disabled
            />
          </div>
          <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-room-name">
              Subject
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              value={roomInfo.subject}
              disabled
            />
          </div>
        </div>
        <Grid container justifyContent="flex-end">
          {roomInfo.roomName ? (
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!roomInfo.userName || !roomInfo.roomName}
              className={classes.continueButton}
            >
              Continue
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              className={classes.continueButton}
              onClick={() => (window.location.href = 'https://www.scholalify.com/')}
            >
              Go Back
            </Button>
          )}
        </Grid>
      </form>
    </>
  );
}
